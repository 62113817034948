<template>
<div>AssetDetail</div>
</template>
<script>
export default {
  name: 'AssetDetail',
  data() {
    return {}
  }
}
</script>
<style scoped lang="less">

</style>